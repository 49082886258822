
<div class="approval-list">
  <approval-filter (inputValue)="filterList($event)" [areas]="academicAreas"></approval-filter>

  <approval-list-header
    [headerNames]="getHeaderNames()">
  </approval-list-header>

  <div class="approval-list__content">
    <ng-container [ngSwitch]="selectedSubject">
      <ng-container *ngSwitchCase="'all'" [ngTemplateOutlet]="all"></ng-container>
      <ng-container *ngSwitchCase="'studying'" [ngTemplateOutlet]="studying"></ng-container>
      <ng-container *ngSwitchCase="'approved'" [ngTemplateOutlet]="approved"></ng-container>
      <ng-container *ngSwitchCase="'pending'" [ngTemplateOutlet]="pending"></ng-container>
      <ng-container *ngSwitchCase="'unapproved'" [ngTemplateOutlet]="unapproved"></ng-container>
      <ng-container *ngSwitchCase="'certified'" [ngTemplateOutlet]="certified"></ng-container>
    </ng-container>
  </div>

  <approval-modal *ngIf="showApprovalModal" [selectedSubjectInfo]="selectedSubjectInfo"
    [userCourseInfo]="userCourseInfo" (closeModal)="showApprovalModal = false" (updateList)="updateSubjectList()">
  </approval-modal>
</div>

<!-- #region APPROVED -->
<ng-template #approved>
  <div class="approval-list__item" *ngFor="let item of data.approved">
    <ng-container *ngIf="item.subjects && item.subjects.length > 0">
      <h2 class="approval-text approval-text--xl approval-text--bold">{{ item.semesterName }}</h2>
      <div class="approval-list__item-card">
        <ng-container *ngFor="let userSubject of item.subjects">
          <subject-approved
            [userSubject]="userSubject"
            [showStatus]="selectedSubject === 'all'"
            (deleteAction)="selectedUserSubjectInfo = $event.userSubjectInfo; selectedSubjectInfo = $event.subjectInfo; deleteSubject();">
          </subject-approved>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region STUDYING -->
<ng-template #studying>
  <div class="approval-list__item" *ngFor="let item of data.studying">
    <ng-container *ngIf="item.subjects && item.subjects.length > 0">
      <h2 class="approval-text approval-text--xl approval-text--bold">{{ item.semesterName }}</h2>
      <div class="approval-list__item-card">
        <ng-container *ngFor="let userSubject of item.subjects">
          <subject-studying
            [userSubject]="userSubject"
            [showStatus]="selectedSubject === 'all'"
            [isWithinDateRange]="isWithinDateRange"
            [userInfo]="userInfo"
            (deleteAction)="selectedUserSubjectInfo = $event.userSubjectInfo; selectedSubjectInfo = $event.subjectInfo; deleteSubject();">
          </subject-studying>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region PENDING -->
<ng-template #pending>
  <div class="approval-list__item" *ngFor="let item of data.pending">
    <ng-container *ngIf="item.subjects && item.subjects.length > 0">
      <h2 class="approval-text approval-text--xl approval-text--bold">{{ item.semesterName }}</h2>
      <div class="approval-list__item-card">
        <ng-container *ngFor="let subject of item.subjects">
          <subject-pending [subjectGroup]="subject"
            [showStatus]="selectedSubject === 'all'"
            (subjectActions)="showApprovalModal = $event.showModal; selectedSubjectInfo = $event.subjectInfo;"
            (deleteAction)="selectedUserSubjectInfo = $event.userSubjectInfo; selectedSubjectInfo = $event.subjectInfo; deleteSubject();">
          </subject-pending>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region UNAPPROVED -->
<ng-template #unapproved>
  <div class="approval-list__item" *ngFor="let item of data.unapproved">
    <ng-container *ngIf="item.subjects && item.subjects.length > 0">
      <h2 class="approval-text approval-text--xl approval-text--bold">{{ item.semesterName }}</h2>
      <div class="approval-list__item-card">
        <ng-container *ngFor="let userSubject of item.subjects">
          <subject-unapproved [userSubject]="userSubject"
            [noteToApprove]="userSubject.subjectGroup.subjects.DesiredGrade"
            [userCourseInfo]="userCourseInfo"
            [showStatus]="selectedSubject === 'all'"
            (updateList)="updateSubjectList()"
            (deleteAction)="selectedUserSubjectInfo = $event.userSubjectInfo; selectedSubjectInfo = $event.subjectInfo; deleteSubject();">
          </subject-unapproved>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!--#region CERTIFIED -->
<ng-template #certified>
  <div class="approval-list__item" *ngFor="let item of data.certified">
    <ng-container *ngIf="item.subjects && item.subjects.length > 0">
      <h2 class="approval-text approval-text--xl approval-text--bold">{{ item.semesterName }}</h2>
      <div class="approval-list__item-card">
        <ng-container *ngFor="let userSubject of item.subjects">
          <subject-certified [userSubject]="userSubject"
            [showStatus]="selectedSubject === 'all'"
            (deleteAction)="selectedUserSubjectInfo = $event.userSubjectInfo; selectedSubjectInfo = $event.subjectInfo; deleteSubject();">
          </subject-certified>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!--#region ALL -->
<ng-template #all>
  <ng-container [ngTemplateOutlet]="studying"></ng-container>
  <ng-container [ngTemplateOutlet]="approved"></ng-container>
  <ng-container [ngTemplateOutlet]="pending"></ng-container>
  <ng-container [ngTemplateOutlet]="unapproved"></ng-container>
  <ng-container [ngTemplateOutlet]="certified"></ng-container>
</ng-template>
<!-- #endregion -->