import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup,  ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap, forkJoin, map, switchMap, throwError } from 'rxjs';
import { ITypeUser } from 'src/app/models/users/type-user';
import { RegisterChildrenService } from 'src/app/services/register-children/register-children.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-children-form',
  templateUrl: './children-form.component.html',
  styleUrls: ['./children-form.component.css'],
})
export class ChildrenFormComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _registerChildrenService: RegisterChildrenService
  ) {}

  @Output('onCloseForm') onCloseForm: EventEmitter<any> = new EventEmitter();
  @Input('currentUserId') currentUserId: number = 0;
  @Input('countries') countries: any[] = [];
  @Input('currentUserEmail') currentUserEmail: string = 'usuario@cedoc.edu.co';

  public schools: any[] = [];
  public courses: any[] = [];
  public forces: any[] = [];
  public ranges: any[] = [];
  public documentTypes: any[] = [];
  public typeBiologicalSex: any[] = [];
  public bloodType: any[] = [];
  public typeUser: ITypeUser[] = [];
  public checkErrors: boolean = false;
  public serverErrors: string = '';
  public SCHOOL_NAME = environment.SCHOOL_NAME;
  public principalAppName = environment.principalAppName;
  public pagination: number = 1;
  public isLoading: boolean = false;
  public registeredChildren: any = undefined;

  get isBasicInfoValid(): boolean {
    const basicInfoControls = this.registerForm.get('basicInfo');
    const contactInfoControls = this.registerForm.get('contactInfo');
    const documentInfoControls = this.registerForm.get('documentInfo');
    const classificationUserInfoControls =
      this.registerForm.get('classificationUser');

    return (
      !!basicInfoControls?.get('firstName')?.valid &&
      !!basicInfoControls?.get('lastName')?.valid &&
      !!documentInfoControls?.get('documentTypeId')?.valid &&
      !!documentInfoControls?.get('document')?.valid &&
      !!basicInfoControls?.get('email')?.valid &&
      !!contactInfoControls?.get('cellphone')?.valid &&
      (this.SCHOOL_NAME === 'CELIC' ? 
          !!contactInfoControls?.get('phone')?.valid &&
          !!contactInfoControls?.get('birthDate')?.valid &&
          !!contactInfoControls?.get('nacionality')?.valid &&
          !!classificationUserInfoControls?.get('biologicalSexId')?.valid &&
          !!classificationUserInfoControls?.get('rhId')?.valid &&
          !!classificationUserInfoControls?.get('eps')?.valid &&
          !!classificationUserInfoControls?.get('ensuranceCompany')?.valid
        : true)
    );
  }

  get progressWidth(): string {
    let value = '0';

    if (this.registerForm.valid) value = '100%';
    else if (this.isBasicInfoValid) value = '50%';

    return value;
  }

  public registerForm: FormGroup = this._fb.group({
    isAChild: true,
    schoolName: ['', Validators.required],
    termsCond: ['', Validators.requiredTrue],

    isTest: [true, Validators.required],
    isMilitary: [false, Validators.required],
    accountType: ['student', Validators.required],

    basicInfo: this._fb.group({
      typeUserId: [5, Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      secondLastName: [''],
      email: ['', [Validators.required, this.emailValidator]],
    }),

    documentInfo: this._fb.group({
      documentTypeId: ['', Validators.required],
      document: ['', Validators.required],
    }),

    contactInfo: this._fb.group({
      cellphone: ['', Validators.required],
      phone: [''],
      birthDate: [''],
      nacionality: [''],
      email: [''],
    }),

    courseInfo: this._fb.group({
      courseOfferId: ['', Validators.required],
      schoolId: ['', Validators.required],
      jobOfferId: [''], //Required if instructor
    }),

    militaryInfo: this._fb.group({
      militaryDocument: [''],
      otanId: [''],
      forceId: [''],
    }),

    classificationUser: this._fb.group({
      biologicalSexId: [''],
      rhId: [''],
      eps: [''],
      ensuranceCompany: [''],
    }),
  });

  ngOnInit(): void {
    //Requied fields for CELIC
    if (this.SCHOOL_NAME === 'CELIC') {
      const requiredFields = [
        'contactInfo.birthDate',
        'contactInfo.nacionality',
        'contactInfo.phone',
        'classificationUser.biologicalSexId',
        'classificationUser.rhId',
        'classificationUser.eps',
        'classificationUser.ensuranceCompany',
      ];

      this.setMassiveValidator(requiredFields, Validators.required);
    }

    this.registerForm.patchValue({
      schoolName: this.SCHOOL_NAME,
      isTest: !environment.production,
    });

    forkJoin({
      documents: this._registerChildrenService.getDocumentTypes(),
      schools: this._registerChildrenService.getSchools(),
      forces: this._registerChildrenService.getForces(),
      biologicalSex: this._registerChildrenService.getBiologicalSex(),
      bloodType: this._registerChildrenService.getBloodType(),
      typeUser: this._registerChildrenService.getUserTypes()
    }).subscribe({
      next: ({ documents, schools, forces, biologicalSex, bloodType, typeUser }: any) => {
        this.documentTypes = documents;
        this.schools = schools;
        this.forces = forces;
        this.typeBiologicalSex = biologicalSex;
        this.bloodType = bloodType;
        this.typeUser = typeUser;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  private findErroredFields(formGroup: FormGroup, erroredFields: any[]) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.findErroredFields(control, erroredFields);
      } else if (control?.errors !== null) {
        erroredFields.push({ field: key, errors: control?.errors });
      }
    });
  }

  /**
   * Inserts a new child
   */
  public registerChild() {
    this.checkErrors = true;
    this.serverErrors = '';

    const erroredFields = [];
    this.findErroredFields(this.registerForm, erroredFields);

    if (!this.registerForm.valid) return;

    //For Celic and cedoc it is necessary to convert the cellphone and phone to string
    //For Cedoc the email of contactInfo is the same as personalInfo
    this.registerForm.patchValue({
      contactInfo: {
        cellphone: this.registerForm
          .get('contactInfo.cellphone')
          ?.value.toString(),
        phone: this.registerForm.get('contactInfo.phone')?.value.toString(),
        email: this.registerForm.get('basicInfo.email')!.value,
      },
    });

    this.isLoading = true;
    this._registerChildrenService
      .registerChild(this.registerForm.value)
      .pipe(
        switchMap((resp: any) => {
          const { data, status } = resp;

          if (status !== 'OK') return throwError(() => resp);

          const newChild: any = {
            ParentUserId: this.currentUserId,
            StudentUserId: data.id,
          };

          return this._registerChildrenService
            .insertParentToStudent(newChild)
            .pipe(map((_) => ({ resp })));
        })
      )
      .subscribe({
        next: ({ resp }) => {
          const { data } = resp;
          this.registeredChildren = {
            email: data.email,
            cellphone: this.registerForm.get('contactInfo.cellphone')?.value,
          };
          this.isLoading = false;
          this.postNotificationQueue();
        },
        error: (err) => {
          this.isLoading = false;
          this.serverErrors = err.error.message || 'Error interno del servidor';
        },
      });
  }

  postNotificationQueue() {
    let data = {
      emailsTo: `["${this.registerForm.get('basicInfo.email')!.value}"]`,
      namesTO: `["Registro en la plataforma ${this.principalAppName.toUpperCase()}"]`,
      msg: `[{"message":"El nombre de usuario del estudiante registrado es ${this.registeredChildren.email}, y la contraseña es el número de celular con el cual lo registró."}]`,
      timeToSend: new Date().toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email',
      senderName: null,
      senderEmail: null,
      atachment: null,
      templateFile: 'template.html',
    };

    this._registerChildrenService.postNotificationQueue(data).subscribe({
      next: () => {},
      error: (err) => {
        console.log(err);
      },
    });
  }

  /**
   * On select school input
   */
  public onSelectSchool() {
    const value: number = this.registerForm.get('courseInfo.schoolId')?.value;

    this.registerForm.get('courseInfo')?.patchValue({ courseOfferId: '' });
    this.courses = [];

    this._registerChildrenService.getCourseOffers(value).subscribe({
      next: (courses: any) => {
        this.courses = courses;
      },
      error: (err) => console.log(err),
    });
  }

  /**
   * On select ranges input
   *
   */
  public onSelectForce() {
    const value: number = this.registerForm.get('militaryInfo.forceId')?.value;

    //Reset range
    this.registerForm.patchValue({ militaryInfo: { otanId: '' } });
    this.ranges = [];

    this._registerChildrenService.getRange(value).subscribe({
      next: (resp) => {
        this.ranges = resp;
      },
      error: (err) => console.log(err),
    });
  }

  /**
   * On select personal type input
   */
  public onSelectPersonalType() {
    this.registerForm.patchValue({
      isMilitary: this.typeUser.find(e => e.id == this.registerForm.get('basicInfo.typeUserId')!.value)!.isMilitar,
    });

    const value: boolean = this.registerForm.get('isMilitary')?.value;

    this.registerForm
      .get('militaryInfo')
      ?.patchValue({ militaryDocument: '', otanId: '', forceId: '' });
    this.ranges = [];

    //Set required fields
    value === true
      ? this.setMassiveValidator(
          [
            'militaryInfo.militaryDocument',
            'militaryInfo.forceId',
            'militaryInfo.otanId',
          ],
          Validators.required
        )
      : this.clearMassiveValidator([
          'militaryInfo.militaryDocument',
          'militaryInfo.forceId',
          'militaryInfo.otanId',
        ]);
  }

  /**
   * Pagination - next
   */
  public nextPage() {
    if (this.pagination < 2) this.pagination++;
  }

  /**
   * Pagination - previous
   */
  public previousPage() {
    if (this.pagination > 1) this.pagination--;
  }

  /**
   * Custom e-mail validator for formControl
   *
   * @param control
   * @returns
   */
  private emailValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const email: string = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email && !emailRegex.test(email)) return { invalidEmailDomain: true };

    return null;
  }

  public clearSpaces(event:Event, controlName:string){
    const input = event.target as HTMLInputElement;
    let value = input.value.trim();
    value = value.replace(/\s+/g, '');
    input.value = value;
    this.registerForm.get(controlName)?.setValue(value);
  }

  private setMassiveValidator(fields: string[], validator: ValidatorFn) {
    fields.forEach((field) => {
      const control = this.registerForm.get(field);
      if (control) {
        control.setValidators(validator);
        control.updateValueAndValidity();
      }
    });
  }

  private clearMassiveValidator(fields: string[]) {
    fields.forEach((field) => {
      const control = this.registerForm.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }
}
