import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, Subscription, switchMap } from 'rxjs';
import { SubjectsI, UserCoursesI, UserI, UserSubjectI } from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'approval-list-military',
  templateUrl: './approval-list-military.component.html',
  styleUrls: ['./approval-list-military.component.css']
})
export class ApprovalListMilitaryComponent implements OnInit, OnDestroy {

  constructor(
    private _approvalService: ApprovalService,
  ) {
    //
  }

  //receive the id of the selected course
  public userCourseInfo: UserCoursesI = {} as UserCoursesI;
  @Input('selectedCourseInfo') set selectedCourseInfo(value: UserCoursesI) {
    if (!value || Object.keys(value).length === 0) //checks if @input is empty
      return;

    this.userCourseInfo = value;
    this.updateSubjectList();
  }

  @Input("isWithinDateRange") isWithinDateRange: boolean = false;
  @Input("userInfo") userInfo: UserI | undefined = undefined;
  @Input('selectedSubject') selectedSubject: string = 'all';
  public showApprovalModal: boolean = false;
  public selectedSubjectInfo: SubjectsI = {} as SubjectsI;
  public selectedUserSubjectInfo: UserSubjectI = {} as UserSubjectI;
  public data: any = {};
  private _data: any = {};
  public academicAreas:any[] = [];
  private _userSubjects: any[] = [];
  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    //
  }

  /**
   * updates the list of subjects of the selected course
   */
  public updateSubjectList() {
    Swal.fire({ 
      title: 'Cargando...', 
      text: "Esto no debería tardar mucho",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();

    this.getSubjectGroups()
      .pipe(
        switchMap((subjectGroups) => {
          const subjectGroupIDs = subjectGroups.map(e => e.id);
          return this.getUserSubjects(subjectGroupIDs).pipe(
            map(userSubjects => ({ subjectGroups, userSubjects }))
          )
        })
      ).subscribe({
        next: ({ subjectGroups, userSubjects }) => {
          this._userSubjects = userSubjects;
          this.setData(userSubjects, subjectGroups);
          this.getAcademicAreas();
          Swal.close();
        },
        error: (err) => this.errorHandler(err)
      })
  }

  private setData(userSubjects: any, subjectGroups: any) {
    this._data = {
      pending: this.getPending(userSubjects, subjectGroups),
      approved: this.getApproved(userSubjects),
      studying: this.getStudying(userSubjects),
      unapproved: this.getUnapproved(userSubjects),
      certified: this.getCertified(userSubjects)
    }

    this.data = { ...this._data };
  }

  //Subjects without userSubject relation
  private getPending(userSubjects: any, groups: any[]) {
    const userSubjectIDs = new Set(userSubjects.map(userSubject => userSubject.SubjectID));
    return groups.filter(g => !userSubjectIDs.has(g.subjects.id))
      .sort((a, b) => a.NameSubjectGroup.localeCompare(b.NameSubjectGroup))
  }

  //All the subjects where is not IsHomolog yet
  private getStudying(userSubjects: UserSubjectI[]) {
    if (userSubjects.length === 0 || !this.isWithinDateRange)
      return [];

    return userSubjects.filter((userSubject: UserSubjectI) => userSubject.userRecord && userSubject.userRecord.length > 0 && !userSubject.IsHomolog)
      .sort((a, b) => a.subjectGroup.subjects.NameSubject.localeCompare(b.subjectGroup.subjects.NameSubject));
  }

  private getApproved(userSubjects: UserSubjectI[]) {
    if (userSubjects.length === 0)
      return [];

    return userSubjects.filter((userSubject: UserSubjectI) =>
      userSubject.userRecord?.every(userRecord => userRecord.IsAccording) &&
      !userSubject.IsHomolog &&
      userSubject.IsAproved
    ).sort((a, b) => a.subjectGroup.subjects.NameSubject.localeCompare(b.subjectGroup.subjects.NameSubject));
  }

  private getUnapproved(userSubjects: UserSubjectI[]) {
    if (userSubjects.length === 0)
      return [];

    return userSubjects.filter((userSubject: UserSubjectI) =>
      userSubject.userRecord?.every(userRecord => userRecord.IsAccording) &&
      !userSubject.IsHomolog && !userSubject.IsAproved
    ).sort((a, b) => a.subjectGroup.subjects.NameSubject.localeCompare(b.subjectGroup.subjects.NameSubject));
  }

  private getCertified(userSubjects: UserSubjectI[]) {
    if (userSubjects.length === 0)
      return [];

    return userSubjects.filter(userSubject => userSubject.IsHomolog)
      .sort((a, b) => a.subjectGroup.subjects.NameSubject.localeCompare(b.subjectGroup.subjects.NameSubject));
  }

  /**
 * is executed each time a key is pressed.
 * @param event
 * @returns
 */
  public filterList(event: any) {
    const subjectName = event.subjectName.toLowerCase();
    const areaId = event.areaId;

    for (const key in this.data) {
      if (this.data.hasOwnProperty(key) && Array.isArray(this.data[key])) {
        this.data[key] = this._data[key].map((data: any) => ({
          ...data,
          subjects: data.subjects.filter((subject: any) => {
            const nameCondition = subjectName.length >= 4
              ? subject.subjectGroup?.subjects?.NameSubject?.toLowerCase()?.includes(subjectName)
              : true;

            const areaCondition = areaId !== 0
              ? subject.subjectGroup?.subjects?.AcademicaArea?.id === areaId
              : true;

            return nameCondition && areaCondition;
          }),
        }));
      }
    }
  }

  /**
   * gets all userSubjects of all subjects
   * @param subjects
   * @returns
   */
  private getUserSubjects(SubjectGroupIDs: number[]) {
    let filter = JSON.stringify({
      where: {
        and: [{ UserID: this.userCourseInfo.UserID }, { SubjectGroupID: { inq: SubjectGroupIDs } }]
      },
      include: [{ subjectGroup: ['userapp', { subjects: 'AcademicaArea' }] }, { userRecord: ['midTerm', 'dateRecord'] }]
    });

    return this._approvalService.getUserSubjects(filter);
  }

  private getSubjectGroups() {
    const filter: string = JSON.stringify({
      where: { and: [{ CourseOferID: this.userCourseInfo.coursesOfers.id }, { CourseGroupID: this.userCourseInfo.CourseGroupID }] },
      include: ['subjects', 'userapp']
    });
    return this._approvalService.getSubjectGroups(filter);
  }

  private getAcademicAreas() {
    const areas = this._userSubjects.map(e => e.subjectGroup?.subjects?.AcademicaArea);
    this.academicAreas = areas.filter(e => e !== undefined)
      .filter((e, index) => areas.findIndex(obj => obj.id === e.id) === index);
  }

  public deleteSubject() {
    if( ![1, 3, 18].some( e => this._approvalService.roles.includes(e)) )
      return;

    Swal.fire({
      icon: 'warning',
      title: "Estas seguro?",
      text: `Estas a punto de eliminar  ${this.selectedSubjectInfo.NameSubject}`,
      allowEscapeKey:false,
      allowOutsideClick:false
    }).then(result => {
      if(result.isConfirmed){
        Swal.fire({ 
          title: "Eliminación en proceso",
          text: 'Por favor, espera un momento.', 
          allowEscapeKey:false,
          allowOutsideClick:false
        });
        Swal.showLoading();
    
        const selectedSubjectId = this.selectedUserSubjectInfo.id;

        this._approvalService.deleteUserSubject(selectedSubjectId).subscribe({
          complete: () => {
            Swal.fire({
              icon:'success',
              title:"Hecho",
              text: "Se ha eliminado la materia",
              allowEscapeKey:false,
              allowOutsideClick: false
            }).then(result => {
              if(result.isConfirmed)
                this.updateSubjectList()
            })
          }, error: (error) => {
            Swal.close();
            console.log(error)
          }
        })
      }
    })
  }

  public getHeaderNames(): string[] {
    const headerNames = ['Materia', 'Inasistencias', 'Nota', 'Habilitar Nota'];
    if (this.selectedSubject === 'all')
      headerNames.push('Estado');

    return headerNames;
  }

  private errorHandler(err) {
    Swal.fire({
      icon: 'error',
      text: err.status === 401 ? "Error de permisos." : "Error al procesar su solicitud."
    });
    console.error(err);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}