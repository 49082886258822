<section class="resume-container">
  <form [formGroup]="userForm" (submit)="saveData()">
    <div class="info-section">
      <div class="resume-container__header">
        <div class="header">
          <h2 class="text text--xl text--bold text--blue-light">
            {{ userData?.Name1 | titlecase }} {{ userData?.Name2 | titlecase }}
            {{ userData?.LastName1 | titlecase }}
            {{ userData?.LastName2 | titlecase }}
          </h2>
          <img [src]="userData?.Photo" alt="" class="user-avatar" />
        </div>
        <h2 class="text text--blue-light text--lg">Hoja de vida</h2>
      </div>

      <div class="basic-info" formGroupName="security" *ngIf="shouldChangePassword">
        <h2 class="text text--md text--bold text--blue">Seguridad</h2>
        <div class="info__col-4">
          <div class="input" [ngClass]="{'input--editable' :  isEditing && allowedRoles([10, 29])}">
            <label for="">Contraseña actual *</label>
            <span class="text" *ngIf="shouldShowField('security.currentPassword').showSpan">-</span>
            <input type="password" formControlName="currentPassword" placeholder="Su contraseña actual"
              [readonly]="!isEditing || !allowedRoles([10, 29])"
              *ngIf="userForm.get('security.currentPassword')?.value || isEditing" />
            <ul class="error">
              <li
                *ngIf="userForm.get('security.currentPassword')?.hasError('required') && userForm.get('security.currentPassword')?.touched">
                Este campo es obligatorio.
              </li>
              <li *ngIf="!isCurrentPasswordValid && userForm.get('security.currentPassword')?.touched &&
                  userForm.get('security.currentPassword')?.value.length > 5">
                Contraseña errónea.
              </li>
              <li *ngIf="userForm.get('security.currentPassword')?.hasError('minlength') &&userForm.get('security.currentPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing && allowedRoles([10, 29])}">
            <label for="">Contraseña nueva *</label>
            <span class="text" *ngIf="shouldShowField('security.newPassword').showSpan">-</span>

            <input type="password" placeholder="Su contraseña actual" formControlName="newPassword"
              [readonly]="!isEditing || !allowedRoles([10, 29])"
              *ngIf="userForm.get('security.newPassword')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('security.newPassword')?.hasError('required') &&
                  userForm.get('security.newPassword')?.touched">
                Este campo es obligatorio.
              </li>
              <li *ngIf=" userForm.get('security.newPassword')?.hasError('minlength') &&
                  userForm.get('security.newPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing && allowedRoles([10, 29])}">
            <label for="">Confirmar contraseña *</label>
            <span class="text" *ngIf="shouldShowField('security.confirmPassword').showSpan">-</span>
            <input type="password" placeholder="Confirmar nueva contraseña" formControlName="confirmPassword"
              [readonly]="!isEditing || !allowedRoles([10, 29])" *ngIf="
                userForm.get('security.confirmPassword')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="
                  userForm.get('security')?.hasError('passwordMismatch') &&
                  userForm.get('security.confirmPassword')?.touched">
                Las contraseñas no coinciden.
              </li>
              <li *ngIf="userForm.get('security.confirmPassword')?.hasError('minlength') &&
                  userForm.get('security.confirmPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
              <li *ngIf="userForm.get('security.confirmPassword')?.hasError('required') &&
                  userForm.get('security.confirmPassword')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="basic-info" >
        <h2 class="text text--md text--bold text--blue">Información básica</h2>
        <div class="info__col-4">
          <div class="input" formGroupName="personalInfo" [ngClass]="{'input--editable' : isEditing && allowedRoles([1, 2, 10, 29])}"> 
            <label for="">Tipo de usuario *</label>
            <span class="text" *ngIf="shouldShowField('personalInfo.typeUserID').showSpan">-</span>
            <div class="select-container" *ngIf="shouldShowField('personalInfo.typeUserID').showDiv">
              <select formControlName="typeUserID" [ngClass]="{'no-events' : !isEditing}" (change)="onChaneUserType()">
                <option value="" selected disabled>Seleccionar</option>
                <option [ngValue]="item.id" *ngFor="let item of typeUser">
                  {{ item.nameTypeUser }}
                </option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing && allowedRoles([1, 2, 10, 29])"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('personalInfo.typeUserID')?.hasError('required') &&
                  userForm.get('personalInfo.typeUserID')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <ng-container formGroupName="documentInfo">
            <div class="input" [ngClass]="{'input--editable' : isEditing && allowedRoles([1])}">
              <label for="">Tipo de documento *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.TypeDocumentID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('documentInfo.TypeDocumentID').showDiv">
                <select name="" formControlName="TypeDocumentID" [ngClass]="{'no-events' : !isEditing}">
                  <option value="" selected disabled>Seleccionar</option>
                  <option [ngValue]="item.id" *ngFor="let item of typeDocuments">
                    {{ item.NameTypeDocument }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
              <ul class="error">
                <li *ngIf="userForm.get('documentInfo.TypeDocumentID')?.hasError('required') 
                  && userForm.get('documentInfo.TypeDocumentID')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing && allowedRoles([1])}">
              <label for="">No. Documento *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.Document').showSpan">-</span>
              <input type="text" formControlName="Document" [readonly]="!isEditing || !allowedRoles([1])"
                *ngIf="userForm.get('documentInfo.Document')?.value || isEditing" />
              <ul class="error">
                <li *ngIf="userForm.get('documentInfo.Document')?.hasError('required') &&
                    userForm.get('documentInfo.Document')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>

            <div class="input">
              <label>Cargar documento</label>
              <ng-container *ngIf="isEditing">
                <button type="button" class="button button--upload-file" (click)="fileInput.click()">
                  <em class="icon icon-i-doc"></em>
                  {{ fileName }}
                </button>
                <input type="file" accept=".pdf" (change)="onFileChange($event)" hidden #fileInput />
              </ng-container>
  
              <ng-container *ngIf="!isEditing">
                <span class="text" *ngIf="!userData?.UserDocuments?.[0]?.DocumentFile">Sin documento</span>
                <a [href]="BACK_V1_URL + this.userData!.UserDocuments[0].DocumentFile
                  "target="_blank" class="document-link" *ngIf="userData?.UserDocuments?.[0]?.DocumentFile"
                  rel="noopener">
                  Ver Documento <em class="icon icon-i-angle-right"></em>
                </a>
              </ng-container>
            </div>

            <div class="input"
              [ngClass]="{'input--editable' : isEditing && !userData?.UserDocuments?.[0]?.DateExpedition}">
              <label for="">Fecha de expedición *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.DateExpedition').showSpan">-</span>
              <input type="date" formControlName="DateExpedition"
                [readonly]="!isEditing || userData?.UserDocuments?.[0]?.DateExpedition"
                *ngIf="shouldShowField('documentInfo.DateExpedition').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('documentInfo.DateExpedition')?.hasError('required') &&
                    userForm.get('documentInfo.DateExpedition')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>

            <div class="input"
              [ngClass]="{'input--editable' : isEditing && !userData?.UserDocuments?.[0]?.PlaceExpedition}">
              <label for="">Lugar de Expedición *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.PlaceExpedition').showSpan">-</span>
              <input type="text" formControlName="PlaceExpedition"
                [readonly]="!isEditing || userData?.UserDocuments?.[0]?.PlaceExpedition"
                *ngIf="shouldShowField('documentInfo.PlaceExpedition').showDiv" />
              <ul class="error">
                <li *ngIf="
                    userForm
                      .get('documentInfo.PlaceExpedition')
                      ?.hasError('required') &&
                    userForm.get('documentInfo.PlaceExpedition')?.touched
                  ">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
          </ng-container>

          <ng-container formGroupName="contactInfo">
            <div class="input" [ngClass]="{'input--editable' : isEditing && !userData?.ContactInfos?.[0]?.birthDate}" >
              <label for="">Fecha de nacimiento *</label>
              <span class="text" *ngIf="shouldShowField('contactInfo.birthDate').showSpan">-</span>
              <input type="date" formControlName="birthDate"
                [readonly]="!isEditing || userData?.ContactInfos?.[0]?.birthDate"
                *ngIf="shouldShowField('contactInfo.birthDate').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('contactInfo.birthDate')?.hasError('required') && userForm.get('contactInfo.birthDate')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>

            <div class="input">
              <label for="">Edad</label>
              <input type="text" [value]="currentAge" readonly />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Correo personal *</label>
              <span class="text" *ngIf="shouldShowField('contactInfo.Email').showSpan">-</span>
              <input type="text" formControlName="Email" [readonly]="!isEditing"
                *ngIf="shouldShowField('contactInfo.Email').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('contactInfo.Email')?.hasError('invalidEmail') && userForm.get('contactInfo.Email')?.touched">
                  Correo no válido.
                </li>
              </ul>
            </div>
          </ng-container>
        </div>

        <div class="info__col-3">
          <div class="input" [ngClass]="{'input--editable' : isEditing && allowedRoles([1])}" formGroupName="personalInfo">
            <label for="">Correo institucional *</label>
            <span class="text" *ngIf="shouldShowField('personalInfo.CedocEmail').showSpan">-</span>
            <input type="text" formControlName="CedocEmail" [readonly]="!isEditing || !allowedRoles([1])" 
              *ngIf="userForm.get('personalInfo.CedocEmail')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('personalInfo.CedocEmail')?.hasError('required') && userForm.get('personalInfo.CedocEmail')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing}" formGroupName="contactInfo">
            <label for="">Dirección del domicilio *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Address').showSpan">-</span>
            <input type="text" formControlName="Address" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Address')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Address')?.hasError('required') && userForm.get('contactInfo.Address')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>
        </div>

        <div class="info__col-4" formGroupName="contactInfo">
          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">Ciudad *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Ciudad').showSpan">-</span>
            <input type="text" formControlName="Ciudad" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Ciudad')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Ciudad')?.hasError('required') && userForm.get('contactInfo.Ciudad')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">País *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Nacionality').showSpan">-</span>
            <div class="select-container" *ngIf="
                userForm.get('contactInfo.Nacionality')?.value || isEditing
              ">
              <select name="" formControlName="Nacionality" [ngClass]="{'no-events' : !isEditing}">
                <option [ngValue]="null" selected disabled>Seleccionar</option>
                <option [ngValue]="item.id" *ngFor="let item of countries">
                  {{ item.country_name }}
                </option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Nacionality')?.hasError('required') && userForm.get('contactInfo.Nacionality')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">Número de teléfono *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Phone').showSpan">-</span>
            <input type="text" formControlName="Phone" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Phone')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Phone')?.invalid && userForm.get('contactInfo.Phone')?.touched">
                Este campo es obligatorio y debe contener solo números.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">Número celular *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.CellPhone').showSpan">-</span>
            <input type="text" formControlName="CellPhone" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.CellPhone')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.CellPhone')?.invalid && userForm.get('contactInfo.CellPhone')?.touched">
                Este campo es obligatorio y debe contener solo números.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="about">
        <div class="about-left">
          <div class="about-left__content" formGroupName="ProfesionalProfile">
            <h2 class="text text--md text--bold text--blue">
              Acerca de {{ userData?.Name1 | titlecase }}
              {{ userData?.LastName1 | titlecase }} *
            </h2>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <span class="text" *ngIf="
                  shouldShowField('ProfesionalProfile.Description').showSpan
                ">-</span>
              <textarea name="" rows="4" class="" [readonly]="!isEditing" formControlName="Description" *ngIf="
                  userForm.get('ProfesionalProfile.Description')?.value ||
                  isEditing
                "></textarea>
            </div>
          </div>

          <!-- WORK INFO -->
          <div class="dynamic-info__container" formArrayName="workInfo">
            <h2 class="text text--md text--bold text--blue">
              Información laboral
            </h2>

            <span *ngIf="workInfoControls.length === 0 && !isEditing">-</span>
            <div *ngIf="workInfoControls.length > 0">
              <div class="dynamic-info__content" *ngFor="let workInfoGroup of workInfoControls; let i = index"
                [formGroupName]="i">
                <div class="dynamic-info__content-header" *ngIf="isEditing">
                  <h2 class="text text--blue text--bold">
                    {{ workInfoGroup.get("Company")?.value || "..." }}
                  </h2>
                  <button class="button button--delete" type="button" [ngClass]="{'disabled' : isLoading}" 
                    (click)="deleteDynamicItem(i, workInfoGroup, 'workInfo', 'WorkInfos')">
                    <em class="icon icon-i-trash"></em> Eliminar
                  </button>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Empresa *</label>
                    <input type="text" formControlName="Company" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('required') && workInfoGroup.get('Company')?.touched">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('minlength') && workInfoGroup.get('Company')?.touched">
                        Debe tener al menos 5 caracteres.
                      </li>
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('maxlength') && workInfoGroup.get('Company')?.touched">
                        No puede tener más de 50 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Fecha *</label>
                    <input type="date" formControlName="Since" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="workInfoGroup.get('Since')?.hasError('required') && workInfoGroup.get('Since')?.touched">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="input" [ngClass]="{'input--editable' : isEditing}">
                  <label for="">Cargo *</label>
                  <input type="text" formControlName="Position" [readonly]="!isEditing" />
                  <ul class="error">
                    <li *ngIf="workInfoGroup.get('Position')?.hasError('required') && workInfoGroup.get('Position')?.touched">
                      Este campo es obligatorio.
                    </li>
                    <li *ngIf="workInfoGroup.get('Position')?.hasError('minlength') && workInfoGroup.get('Position')?.touched">
                      Debe tener al menos 5 caracteres.
                    </li>
                    <li *ngIf="workInfoGroup.get('Position')?.hasError('maxlength') && workInfoGroup.get('Position')?.touched">
                      No puede tener más de 50 caracteres.
                    </li>
                  </ul>
                </div>

                <div class="input" [ngClass]="{'input--editable' : isEditing}">
                  <label for="">Descripción del cargo *</label>
                  <textarea name="" id="" rows="4" class="" formControlName="Description"
                    [readonly]="!isEditing"></textarea>
                  <ul class="error">
                    <li *ngIf="workInfoGroup.get('Description')?.hasError('required') && workInfoGroup.get('Description')?.touched">
                      Este campo es obligatorio.
                    </li>
                    <li *ngIf="workInfoGroup.get('Description')?.hasError('minlength') && workInfoGroup.get('Description')?.touched">
                      Debe ser mayor a 5 caracteres.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <button class="button button--add" type="button" [ngClass]="{'disabled' : isLoading}"
              (click)="addWorkInfo()" *ngIf="isEditing">
              Agregar
            </button>
          </div>

          <!-- SCIENTIFIC INFO -->
          <div class="dynamic-info__container" formArrayName="scientificInfo">
            <h2 class="text text--md text--bold text--blue">
              Información Investigativa
            </h2>

            <span *ngIf="scientificInfoControls.length === 0 && !isEditing">-</span>
            <div *ngIf="scientificInfoControls.length > 0">
              <div class="dynamic-info__content" *ngFor="
                  let investigativeInfo of scientificInfoControls;
                  let i = index
                " [formGroupName]="i">
                <div class="dynamic-info__content-header" *ngIf="isEditing">
                  <h2 class="text text--blue text--bold">
                    {{ investigativeInfo.get("Title")?.value || "..." }}
                  </h2>
                  <button class="button button--delete" type="button" [ngClass]="{'disabled' : isLoading}" (click)="
                      deleteDynamicItem(
                        i,
                        investigativeInfo,
                        'scientificInfo',
                        'ScientificInfos'
                      )
                    ">
                    <em class="icon icon-i-trash"></em> Eliminar
                  </button>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Title</label>
                    <input type="text" formControlName="Title" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="
                          investigativeInfo
                            .get('Title')
                            ?.hasError('required') &&
                          investigativeInfo.get('Title')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="
                          investigativeInfo
                            .get('Title')
                            ?.hasError('minlength') &&
                          investigativeInfo.get('Title')?.touched
                        ">
                        El texto debe ser mayor a 5 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Fecha de publicación</label>
                    <input type="date" formControlName="PublicationDate" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="
                          investigativeInfo
                            .get('PublicationDate')
                            ?.hasError('required') &&
                          investigativeInfo.get('PublicationDate')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="input" [ngClass]="{'input--editable' : isEditing}">
                  <label for="">Institución</label>
                  <input type="text" formControlName="Institute" [readonly]="!isEditing" />
                  <ul class="error">
                    <li *ngIf="
                        investigativeInfo
                          .get('Institute')
                          ?.hasError('required') &&
                        investigativeInfo.get('Institute')?.touched
                      ">
                      Este campo es obligatorio.
                    </li>
                    <li *ngIf="
                        investigativeInfo
                          .get('Institute')
                          ?.hasError('minlength') &&
                        investigativeInfo.get('Institute')?.touched
                      ">
                      El texto debe ser mayor a 5 caracteres.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <button class="button button--add" type="button" [ngClass]="{'disabled' : isLoading}"
              (click)="addScientificInfo('scientificInfo')" *ngIf="isEditing">
              Agregar
            </button>
          </div>

          <!-- AWARDS INFO -->
          <div class="dynamic-info__container" formGroupName="ClassificationUser">
            <h2 class="text text--md text--bold text--blue">Reconocimientos</h2>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <ng-container *ngIf="isEditing">
                <textarea name="" rows="2" formControlName="specialRecognition"></textarea>
                <p class="text text--italic">
                  Separa los Reconocimientos por comas.
                </p>
              </ng-container>

              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.specialRecognition')
                    .showSpan
                ">-</span>
              <ul class="item-list" *ngIf="
                  userForm.get('ClassificationUser.specialRecognition')
                    ?.value && !isEditing
                ">
                <li class="text texr--gray" *ngFor="
                    let item of textArray(
                      userForm.get('ClassificationUser.specialRecognition')
                        ?.value || ''
                    );
                    let i = index
                  ">
                  {{ i + 1 }}. {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="about-right">
          <!-- ACADEMIC INFO -->
          <div class="dynamic-info__container" formArrayName="academicInfo">
            <h2 class="text text--md text--bold text--blue">
              Formación académica
            </h2>

            <span *ngIf="academicInfoControls.length === 0 && !isEditing">-</span>
            <div *ngIf="academicInfoControls.length > 0">
              <div class="dynamic-info__content" *ngFor="let academicInfo of academicInfoControls; let i = index"
                [formGroupName]="i">
                <div class="dynamic-info__content-header" *ngIf="isEditing">
                  <h2 class="text text--blue text--bold">
                    {{ academicInfo.get("Title")?.value || "..." }}
                  </h2>
                  <button class="button button--delete" type="button" [ngClass]="{'disabled' : isLoading}" (click)="
                      deleteDynamicItem(
                        i,
                        academicInfo,
                        'academicInfo',
                        'AcademicInfos'
                      )
                    ">
                    <em class="icon icon-i-trash"></em> Eliminar
                  </button>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Título *</label>
                    <input type="text" formControlName="Title" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="
                          academicInfo.get('Title')?.hasError('required') &&
                          academicInfo.get('Title')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="
                          academicInfo.get('Title')?.hasError('minlength') &&
                          academicInfo.get('Title')?.touched
                        ">
                        El texto debe ser mayor a 5 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Fecha *</label>
                    <input type="date" formControlName="FinishDate" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="
                          academicInfo
                            .get('FinishDate')
                            ?.hasError('required') &&
                          academicInfo.get('FinishDate')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Institución *</label>
                    <input type="text" formControlName="Institute" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="
                          academicInfo.get('Institute')?.hasError('required') &&
                          academicInfo.get('Institute')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="
                          academicInfo
                            .get('Institute')
                            ?.hasError('minlength') &&
                          academicInfo.get('Institute')?.touched
                        ">
                        El texto debe ser mayor a 5 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{'input--editable' : isEditing}">
                    <label for="">Tipo de curso *</label>
                    <div class="select-container">
                      <select name="" id="" formControlName="TypeCourseID" [ngClass]="{'no-events' : !isEditing}">
                        <option [ngValue]="null" selected disabled>
                          Seleccionar
                        </option>
                        <option [ngValue]="item.id" *ngFor="let item of typeCourses">
                          {{ item.NameTypeCourse }}
                        </option>
                      </select>
                      <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                    </div>
                    <ul class="error">
                      <li *ngIf="
                          academicInfo
                            .get('TypeCourseID')
                            ?.hasError('required') &&
                          academicInfo.get('TypeCourseID')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <button class="button button--add" type="button" [ngClass]="{'disabled' : isLoading}"
              (click)="addAcademicInfo()" *ngIf="isEditing">
              Agregar
            </button>
          </div>

          <!-- SKILLS INFO -->
          <div class="dynamic-info__container" formGroupName="ProfesionalProfile">
            <h2 class="text text--md text--bold text--blue">Habilidades</h2>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <ng-container *ngIf="isEditing">
                <textarea name="" rows="2" formControlName="Skills"></textarea>
                <p class="text text--italic">
                  Separa las habilidades por comas.
                </p>
              </ng-container>

              <span class="text" *ngIf="shouldShowField('ProfesionalProfile.Skills').showSpan">-</span>
              <ul class="item-list" *ngIf="
                  userForm.get('ProfesionalProfile.Skills')?.value && !isEditing
                ">
                <li class="text texr--gray" *ngFor="
                    let item of textArray(
                      userForm.get('ProfesionalProfile.Skills')?.value || ''
                    );
                    let i = index
                  ">
                  {{ i + 1 }}. {{ item }}
                </li>
              </ul>
            </div>
          </div>

          <!-- LANGUAGES -->
          <div class="dynamic-info__container" formGroupName="ProfesionalProfile">
            <h2 class="text text--md text--bold text--blue">Idiomas</h2>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <ng-container *ngIf="isEditing">
                <textarea name="" rows="2" formControlName="Language"></textarea>
                <p class="text text--italic">Separa los idiomas por comas.</p>
              </ng-container>

              <span class="text" *ngIf="shouldShowField('ProfesionalProfile.Language').showSpan">-</span>
              <ul class="item-list" *ngIf="
                  userForm.get('ProfesionalProfile.Language')?.value &&
                  !isEditing
                ">
                <li class="text texr--gray" *ngFor="
                    let item of textArray(
                      userForm.get('ProfesionalProfile.Language')?.value || ''
                    );
                    let i = index
                  ">
                  {{ i + 1 }}. {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-section">
      <div class="info-section__content">
        <h2 class="text text--blue-light text--lg text--bold">
          Información personal
        </h2>
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información demográfica
          </h2>
          <div class="info__col-4" formGroupName="ClassificationUser">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Grupo étnico</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ethnicalGroupID').showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ethnicalGroupID').showDiv
                ">
                <select name="" formControlName="ethnicalGroupID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeEthnicalGroup">
                    {{ item.nameEthnicalGroup }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Comunidad indígena adscrita</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.indigenousPeopleID')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.indigenousPeopleID')
                    .showDiv
                ">
                <select name="" formControlName="indigenousPeopleID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeIndigenousPeople">
                    {{ item.nameIndigeniusPeople }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Comunidad Negra</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.blackCommunitiesID')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.blackCommunitiesID')
                    .showDiv
                ">
                <select name="" formControlName="blackCommunitiesID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of blackCommunities">
                    {{ item.nameCommunity }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">¿Posee capacidad excepcional?</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.exceptionalCapacityID')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.exceptionalCapacityID')
                    .showDiv
                ">
                <select name="" formControlName="exceptionalCapacityID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeExceptionalCapacity">
                    {{ item.nameCapacity }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Desplazado</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.Displaced').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.Displaced').showDiv">
                <select name="" formControlName="Displaced" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Si'">Si</option>
                  <option [value]="'No'">No</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.Displaced')?.value === 'Si'
              ">
              <label for="">Municipio de donde es desplazado</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.DisplacingMunicipality')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="DisplacingMunicipality" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.DisplacingMunicipality')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">¿Posee discapacidad?</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.handicappedID').showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.handicappedID').showDiv
                ">
                <select name="" formControlName="handicappedID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeHandicapped">
                    {{ item.disabilityName }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Víctima del conflicto</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ConflictVictim').showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ConflictVictim').showDiv
                ">
                <select name="" formControlName="ConflictVictim" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Si'">Si</option>
                  <option [value]="'No'">No</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.ConflictVictim')?.value ===
                'Si'
              ">
              <label for="">Municipio donde fue victima del conflicto</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.MunicipalityWhereHeWasAVictimOfTheConflict'
                  ).showSpan
                ">-</span>
              <input type="text" formControlName="MunicipalityWhereHeWasAVictimOfTheConflict" [readonly]="!isEditing"
                *ngIf="
                  shouldShowField(
                    'ClassificationUser.MunicipalityWhereHeWasAVictimOfTheConflict'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Sexo biológico</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.biologicalSexID').showSpan
                ">-</span>

              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.biologicalSexID').showDiv
                ">
                <select name="" formControlName="biologicalSexID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeBiologicalSex">
                    {{ item.nameBiologicalSex }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Estado civil</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.civilStatusID').showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.civilStatusID').showDiv
                ">
                <select name="" formControlName="civilStatusID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeCivilStatus">
                    {{ item.nameCivilStatus }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content" formGroupName="ClassificationUser">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información Adicional
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Afición deportiva</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SportsInterest').showSpan
                ">-</span>
              <input type="text" formControlName="SportsInterest" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SportsInterest').showDiv
                " />
            </div>
          </div>

          <div class="info__col-3">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Tatuajes</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.Tattoo').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.Tattoo').showDiv">
                <select name="" formControlName="Tattoo" [ngClass]="{'no-events' : !isEditing}" #tattoo>
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Si'">Si</option>
                  <option [value]="'No'">No</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}"
              *ngIf="userForm.get('ClassificationUser.Tattoo')?.value === 'Si'">
              <label for="">Cantidad, ubicación y descripción de tatuajes</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.QuantityTattooLocationAndDescriptionOfTheFigure'
                  ).showSpan
                ">-</span>
              <input type="text" formControlName="QuantityTattooLocationAndDescriptionOfTheFigure"
                [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.QuantityTattooLocationAndDescriptionOfTheFigure'
                  ).showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content" formGroupName="ClassificationUser">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Socioeconómica</h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Estrato social</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.estratoID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.estratoID').showDiv">
                <select name="" formControlName="estratoID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeEstratos">
                    {{ item.nameEstrato }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Medio de transporte</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.TransportationMethod')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="TransportationMethod" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.TransportationMethod')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Zona de residencia</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.zoneID').showSpan">-</span>

              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.zoneID').showDiv">
                <select name="" formControlName="zoneID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeZones">
                    {{ item.nameZone }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Ingreso económico total del núcleo familiar</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FamilyIncome').showSpan
                ">-</span>
              <input type="text" formControlName="FamilyIncome" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FamilyIncome').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Sisbén</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SisbenSubgroup').showSpan
                ">-</span>
              <input type="text" formControlName="SisbenSubgroup" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SisbenSubgroup').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Nivel de sisbén</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.sisbenID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.sisbenID').showDiv">
                <select name="" formControlName="sisbenID" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeSisben">
                    {{ item.nameSisben }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Tipo de vivienda</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.HousingType').showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.HousingType').showDiv
                ">
                <select name="" formControlName="HousingType" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Propia'">Propia</option>
                  <option [value]="'Arrendada'">Arrendada</option>
                  <option [value]="'Familiar'">Familiar</option>
                  <option [value]="'Otra'">Otra</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Información familiar -->
    <div class="info-section" formGroupName="ClassificationUser" *ngIf="principalAppName == 'cedoc'">
      <h2 class="text text--blue-light text--lg text--bold">
        Información familiar
      </h2>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Situación Familiar y Estructural
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Hijo de Miembro de la Fuerza Pública</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ChildOfPublicForceMember')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ChildOfPublicForceMember')
                    .showDiv
                ">
                <select name="" formControlName="ChildOfPublicForceMember" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Ejército Nacional de Colombia'">
                    Ejército Nacional de Colombia
                  </option>
                  <option [value]="'Policía'">Policía</option>
                  <option [value]="'Armada Nacional'">Armada Nacional</option>
                  <option [value]="'Fuerza Aérea'">Fuerza Aérea</option>
                  <option [value]="'INPEC'">INPEC</option>
                  <option [value]="'NO APLICA'">No aplica</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Cantidad de personas del grupo familiar
                <span class="text--sm">(incluido el aspirante)</span></label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.NumberOfPeopleInTheFamilyGroup'
                  ).showSpan
                ">-</span>

              <input type="number" formControlName="NumberOfPeopleInTheFamilyGroup" [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.NumberOfPeopleInTheFamilyGroup'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">No. Hermanos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.NumberSiblings').showSpan
                ">-</span>
              <input type="number" formControlName="NumberSiblings" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NumberSiblings').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">No. Posición entre Hermanos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SiblingPosition').showSpan
                ">-</span>
              <input type="number" formControlName="SiblingPosition" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SiblingPosition').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">No. Hermanos con Educación Superior</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SiblingsHigherEducation')
                    .showSpan
                ">-</span>
              <input type="number" formControlName="SiblingsHigherEducation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SiblingsHigherEducation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Si tiene Hijos indique el número de hijos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.NumberChildren').showSpan
                ">-</span>
              <input type="number" formControlName="NumberChildren" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NumberChildren').showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información de la Madre
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Estado civil de los padres</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MaritalStatusOfParents')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.MaritalStatusOfParents')
                    .showDiv
                ">
                <select name="" formControlName="MaritalStatusOfParents" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Casados'">Casados</option>
                  <option [value]="'Union libre'">Union libre</option>
                  <option [value]="'Separados'">Separados</option>
                  <option [value]="'Divorciados'">Divorciados</option>
                  <option [value]="'Viudo(a)'">Viudo(a)</option>
                  <option [value]="'Otra'">Otra</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Madre viva o fallecida</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherLivingOrDeceased')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.MotherLivingOrDeceased')
                    .showDiv
                ">
                <select name="" formControlName="MotherLivingOrDeceased" [ngClass]="{'no-events' : !isEditing}"
                  #motherLivingOrDeceased>
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Vive'">Vive</option>
                  <option [value]="'Falleció'">Falleció</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Nombre de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersName').showSpan
                ">-</span>
              <input type="text" formControlName="MothersName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersName').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Número de cédula de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersCard').showSpan
                ">-</span>
              <input type="text" formControlName="MothersCard" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersCard').showDiv
                " />
            </div>
          </div>

          <div class="info__col-3">
            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Teléfono de la madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersTelephone')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MothersTelephone" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersTelephone').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Ocupación de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersOccupation')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MothersOccupation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersOccupation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Dirección de la madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherAddress').showSpan
                ">-</span>
              <input type="text" formControlName="MotherAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MotherAddress').showDiv
                " />
            </div>
          </div>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Email de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherEmailAddress')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MotherEmailAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MotherEmailAddress')
                    .showDiv
                " />
              <ul class="error">
                <li *ngIf="
                    userForm
                      .get('ClassificationUser.MotherEmailAddress')
                      ?.hasError('invalidEmail') &&
                    userForm.get('ClassificationUser.MotherEmailAddress')
                      ?.touched
                  ">
                  Correo no válido.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información del padre
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Padre vivo o fallecido</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FatherLivingOrDeceased')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.FatherLivingOrDeceased')
                    .showDiv
                ">
                <select name="" formControlName="FatherLivingOrDeceased" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Vive'">Vive</option>
                  <option [value]="'Falleció'">Falleció</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Nombre del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersName').showSpan
                ">-</span>
              <input type="text" formControlName="FathersName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersName').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Número de cédula del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersCertificate')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersCertificate" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersCertificate')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Email del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ParentEmailAddress')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="ParentEmailAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.ParentEmailAddress')
                    .showDiv
                " />
              <ul class="error">
                <li *ngIf="
                    userForm
                      .get('ClassificationUser.ParentEmailAddress')
                      ?.hasError('invalidEmail') &&
                    userForm.get('ClassificationUser.ParentEmailAddress')
                      ?.touched
                  ">
                  Correo no válido.
                </li>
              </ul>
            </div>
          </div>

          <div class="info__col-3">
            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Teléfono del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersTelephone')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersTelephone" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersTelephone').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Ocupación del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersOccupation')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersOccupation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersOccupation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Dirección del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FatherAddress').showSpan
                ">-</span>
              <input type="text" formControlName="FatherAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FatherAddress').showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Vínculos con Fuerzas Armadas
          </h2>

          <div class="info__col-3">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">¿Tiene Familiar en las Fuerzas Armadas?</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamily')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamily')
                    .showDiv
                ">
                <select name="" formControlName="ArmedForcesFamily" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Si'">Si</option>
                  <option [value]="'No'">No</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}" 
              *ngIf="userForm.get('ClassificationUser.ArmedForcesFamily')?.value === 'Si'">
              <label for="">Nombre del Familiar</label>
              <span class="text" 
                *ngIf="shouldShowField('ClassificationUser.ArmedForcesFamilyName').showSpan">-</span>
              <input type="text" formControlName="ArmedForcesFamilyName" [readonly]="!isEditing" 
                *ngIf="shouldShowField('ClassificationUser.ArmedForcesFamilyName').showDiv" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-section" formGroupName="ClassificationUser">
      <h2 class="text text--blue-light text--lg text--bold">Información académica</h2>

      <div class="academic-actions" *ngIf="allowedRoles([2, 3, 1, 29])">
        <button type="button" class="button button--upload-file" (click)="openPath('/academicStatus')">
          <em class="icon icon-i-academic-record"></em>
          Historial académico
        </button>
      </div>

      <div class="info-section__content" *ngIf="principalAppName == 'cedoc'">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Nivel de Escolaridad</h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Escolaridad</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.schoolingLevel').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.schoolingLevel').showDiv">
                <select name="" formControlName="schoolingLevel" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>Seleccionar</option>
                  <option [value]="'Básica'">Básica</option>
                  <option [value]="'Media'">Media</option>
                  <option [value]="'Superior'">Superior</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content" *ngIf="principalAppName == 'cedoc'">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Información del Bachillerato</h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Nombre de la institución educativa del bachillerato</label>
              <span class="text" 
                *ngIf="shouldShowField('ClassificationUser.NameOfHighSchoolEducationalInstitution').showSpan">-</span>
              <input type="text" formControlName="NameOfHighSchoolEducationalInstitution" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NameOfHighSchoolEducationalInstitution').showDiv" />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Jornada</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.WorkingDay').showSpan
                ">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.WorkingDay').showDiv">
                <select name="" formControlName="WorkingDay" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Mañana'">Mañana</option>
                  <option [value]="'Tarde'">Tarde</option>
                  <option [value]="'Noche'">Noche</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Calendario (A o B)</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.Schedule').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.Schedule').showDiv">
                <select name="" formControlName="Schedule" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'A'">A</option>
                  <option [value]="'B'">B</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Pública o Privada</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.PublicOrPrivateNature')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.PublicOrPrivateNature')
                    .showDiv
                ">
                <select name="" formControlName="PublicOrPrivateNature" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Publica'">Pública</option>
                  <option [value]="'Privada'">Privada</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Presencial o a distancia</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.PresentialOrDistanceMethodology'
                  ).showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField(
                    'ClassificationUser.PresentialOrDistanceMethodology'
                  ).showDiv
                ">
                <select name="" formControlName="PresentialOrDistanceMethodology"
                  [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Presencial'">Presencial</option>
                  <option [value]="'Distancia'">Distancia</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Tipo de bachillerato</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.TypeOfHighSchool')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.TypeOfHighSchool').showDiv
                ">
                <select name="" formControlName="TypeOfHighSchool" [ngClass]="{'no-events' : !isEditing}">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'ACADÉMICO'">ACADEMICO</option>
                  <option [value]="'TÉCNICO'">TECNICO</option>
                  <option [value]="'BÁSICO'">BASICO</option>
                  <option [value]="'VALIDACIÓN'">VALIDACION</option>
                  <option [value]="'OTRO'">OTRO</option>
                  <option [value]="'COMERCIAL'">COMERCIAL</option>
                  <option [value]="'NORMALISTA SUPERIOR'">
                    NORMALISTA SUPERIOR
                  </option>
                  <option [value]="'ENFOQUE MILITAR'">ENFOQUE MILITAR</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Especialidad de Formación Secundaria</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.SecondaryEducationSpecialty'
                  ).showSpan
                ">-</span>
              <input type="text" formControlName="SecondaryEducationSpecialty" [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.SecondaryEducationSpecialty'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Fecha de grado del bachillerato del aspirante</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.DateAspirantBaccalaureateDegree'
                  ).showSpan
                ">-</span>
              <input type="date" formControlName="DateAspirantBaccalaureateDegree" [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.DateAspirantBaccalaureateDegree'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Código de la Institución</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.InstitutionCode').showSpan
                ">-</span>
              <input type="text" formControlName="InstitutionCode" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.InstitutionCode').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Carácter Institución</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.InstitutionCode').showSpan
                ">-</span>
              <input type="text" formControlName="InstitutionCode" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.InstitutionCode').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Metodología de la Institución</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.Methodology').showSpan
                ">-</span>
              <input type="text" formControlName="Methodology" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.Methodology').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Título Obtenido</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ObtainedDegree').showSpan
                ">-</span>
              <input type="text" formControlName="ObtainedDegree" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.ObtainedDegree').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Acta de Grado No</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.GraduationRecordNo')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="GraduationRecordNo" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.GraduationRecordNo')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Folio Diploma No</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.DiplomaFolioNo').showSpan
                ">-</span>
              <input type="text" formControlName="DiplomaFolioNo" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.DiplomaFolioNo').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Código ICFES</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.ICFESCode').showSpan">-</span>
              <input type="text" formControlName="ICFESCode" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.ICFESCode').showDiv" />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Puntaje ICFES</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ICFESScore').showSpan
                ">-</span>
              <input type="text" formControlName="ICFESScore" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.ICFESScore').showDiv" />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Fecha Presentación ICFES</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ICFESPresentationDate')
                    .showSpan
                ">-</span>
              <input type="date" formControlName="ICFESPresentationDate" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.ICFESPresentationDate')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Año del Informe de Resultados ICFES</label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.YearICFESPruebasSaberReport'
                  ).showSpan
                ">-</span>
              <input type="text" formControlName="YearICFESPruebasSaberReport" [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.YearICFESPruebasSaberReport'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Resultado componente Lectura crítica</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.LecturaCriticaResult')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="LecturaCriticaResult" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.LecturaCriticaResult')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Resultado componente matemáticas</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MathResult').showSpan
                ">-</span>
              <input type="text" formControlName="MathResult" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.MathResult').showDiv" />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Resultado componente sociales ciudadanas</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SocialStudiesResult')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="SocialStudiesResult" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SocialStudiesResult')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Resultado componente Ciencias Naturales</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.NaturalSciencesResult')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="NaturalSciencesResult" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NaturalSciencesResult')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Resultado Área Inglés</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.EnglishAreaResult')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="EnglishAreaResult" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.EnglishAreaResult')
                    .showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content" *ngIf="principalAppName == 'cedoc'">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información del Acudiente
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Nombre Acudiente</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.GuardianName').showSpan
                ">-</span>
              <input type="text" formControlName="GuardianName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.GuardianName').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">No. Cédula del Acudiente</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.GuardianIDNumber')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="GuardianIDNumber" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.GuardianIDNumber').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Teléfono Acudiente</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.GuardianPhone').showSpan
                ">-</span>
              <input type="text" formControlName="GuardianPhone" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.GuardianPhone').showDiv
                " />
            </div>
          </div>

          <div class="info__col-2">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Dirección Acudiente</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.GuardianAddress').showSpan
                ">-</span>
              <input type="text" formControlName="GuardianAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.GuardianAddress').showDiv
                " />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-section">
      <h2 class="text text--blue-light text--lg text--bold">Información militar</h2>

      <div class="info-section__content" formGroupName="militarInfo" *ngIf="isPublicMilitar">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Información básica</h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Tipo de Fuerza*</label>
              <span class="text" *ngIf="shouldShowField('militarInfo.ForceID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('militarInfo.ForceID').showDiv">
                <select formControlName="ForceID" [ngClass]="{'no-events' : !isEditing}" (change)="onSelectForce()">
                  <option value="" selected disabled>Seleccionar</option>
                  <option [value]="item.id" *ngFor="let item of typeForces">{{ item.NameForce }}</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
              <ul class="error">
                <li *ngIf="userForm.get('militarInfo.ForceID')?.hasError('required') 
                  && userForm.get('militarInfo.ForceID')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>

            <div class="input" [ngClass]="{'input--editable' : isEditing}">
              <label for="">Rango*</label>
              <span class="text" *ngIf="shouldShowField('militarInfo.realRangeID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('militarInfo.realRangeID').showDiv">
                <select name="" formControlName="realRangeID" [ngClass]="{'no-events' : !isEditing}">
                  <option value="" selected disabled>Seleccionar</option>
                  <option [value]="item.id" *ngFor="let item of ranges">{{item.NameRange}}</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
              <ul class="error">
                <li *ngIf="userForm.get('militarInfo.realRangeID')?.hasError('required') 
                  && userForm.get('militarInfo.realRangeID')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ng-container formGroupName="ClassificationUser">
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Condición / Documentación</h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Condición Actual</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.CurrentStatus').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.CurrentStatus').showDiv">
                  <select name="" formControlName="CurrentStatus" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Soldado Multimisión (En Servicio Militar Activo) EJC-FAC-ARC'">
                      Soldado Multimisión (En Servicio Militar Activo) EJC-FAC-ARC
                    </option>
                    <option [value]="'Ponal (Axiliar Bachiller y/o Patrullero)'">
                      Ponal (Axiliar Bachiller y/o Patrullero)
                    </option>
                    <option [value]="'INPEC'">INPEC</option>
                    <option [value]="'Civil'">Civil</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Libreta militar</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.MilitaryID').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.MilitaryID').showDiv">
                  <select name="" formControlName="MilitaryID" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>Seleccionar</option>
                    <option [value]="'Si'">Si</option>
                    <option [value]="'No'">No</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" 
                *ngIf="userForm.get('ClassificationUser.MilitaryIDNumber')?.value === 'Si'">
                <label for="">No. Libreta Militar</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.MilitaryIDNumber').showSpan">-</span>
                <input type="text" formControlName="MilitaryIDNumber" [readonly]="!isEditing" 
                  *ngIf="shouldShowField('ClassificationUser.MilitaryIDNumber').showDiv"/>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" 
                *ngIf="userForm.get('ClassificationUser.MilitaryIDNumber')?.value === 'Si'">
                <label for="">Fecha Expedición de la Libreta</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.DocumentIssuanceDate').showSpan">-</span>
                <input type="date" formControlName="DocumentIssuanceDate" [readonly]="!isEditing" 
                  *ngIf="shouldShowField('ClassificationUser.DocumentIssuanceDate').showDiv" />
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Zona de reclutamiento</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.recruitingZone').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.recruitingZone').showDiv">
                  <select name="" formControlName="recruitingZone" [ngClass]="{'no-events' : !isEditing}"
                    (change)="onSelectRecruitingZone()">
                    <option [ngValue]="null" selected disabled>Seleccionar</option>
                    <option [value]="item.name" *ngFor="let item of recruitmentZones">{{ item.name }}</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Distrito Militar</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.MilitaryDistrict').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.MilitaryDistrict').showDiv">
                  <select formControlName="MilitaryDistrict" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>Seleccionar</option>
                    <option [value]="zone" *ngFor="let zone of recruitmentDistrict">{{ zone }}</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Vestimenta</h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Talla camuflado</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.CamouflageSize').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.CamouflageSize').showDiv
                  ">
                  <select name="" formControlName="CamouflageSize" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="34">34</option>
                    <option [value]="36">36</option>
                    <option [value]="38">38</option>
                    <option [value]="40">40</option>
                    <option [value]="42">42</option>
                    <option [value]="44">44</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Servicio Militar</h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Servicio Militar - Tipo Soldado</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.MilitaryService').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.MilitaryService').showDiv
                  ">
                  <select name="" formControlName="MilitaryService" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Si'">Si</option>
                    <option [value]="'No'">No</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Fuerza Tipo Soldado</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.SoldierForceType')
                      .showSpan
                  ">-</span>
                <input type="text" formControlName="SoldierForceType" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.SoldierForceType').showDiv
                  " />
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Unidad (Servicio Militar)</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.UnitMilitaryService')
                      .showSpan
                  ">-</span>
                <input type="text" formControlName="UnitMilitaryService" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.UnitMilitaryService')
                      .showDiv
                  " />
              </div>
  
              <div class="input" [ngClass]="{'input--editable': isEditing}" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Grado</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.Grade').showSpan">-</span>
                <input type="text" formControlName="Grade" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.Grade').showDiv" />
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Contingente</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.Contingent').showSpan
                  ">-</span>
                <input type="text" formControlName="Contingent" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.Contingent').showDiv" />
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Año de Contingente</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.ContingentYear').showSpan
                  ">-</span>
                <input type="text" formControlName="ContingentYear" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.ContingentYear').showDiv
                  " />
              </div>
            </div>
          </div>
        </div>
  
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Ley de Veteranos</h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Ley de veteranos</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.VeteransLaw').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.VeteransLaw').showDiv
                  ">
                  <select name="" formControlName="VeteransLaw" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Veterano'">Veterano</option>
                    <option [value]="'Cónyuge del veterano'">
                      Cónyuge del veterano
                    </option>
                    <option [value]="'Hijo del veterano hasta los 25 años'">
                      Hijo del veterano hasta los 25 años
                    </option>
                    <option [value]="'Padre o madre del veterano'">
                      Padre o madre del veterano
                    </option>
                    <option [value]="'No aplica'">No aplica</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">
              Salud y Seguridad Social
            </h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Grupo Sanguíneo</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.BloodType').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.BloodType').showDiv">
                  <select name="" formControlName="BloodType" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'A'">A</option>
                    <option [value]="'B'">B</option>
                    <option [value]="'AB'">AB</option>
                    <option [value]="'O'">O</option>
                    <option [value]="'Nulo'">Nulo</option>
                    <option [value]="'Otro'">Otro</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Factor RH</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.HrFactor').showSpan">-</span>
                <div class="select-container" *ngIf="shouldShowField('ClassificationUser.HrFactor').showDiv">
                  <select name="" formControlName="HrFactor" [ngClass]="{'no-events' : !isEditing}">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Positivo'">Positivo</option>
                    <option [value]="'Negativo'">Negativo</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="info-section__content" formGroupName="ClassificationUser">
      <div class="basic-info">
        <h2 class="text text--md text--bold text--blue">Seguridad Social</h2>

        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Salud</h2>
  
            <div class="info__col-4">
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">EPS (Entidad Promotora de Salud)</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.eps').showSpan">-</span>
                <input type="text" formControlName="eps" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.eps').showDiv" />
              </div>
  
              <div class="input" [ngClass]="{'input--editable' : isEditing}">
                <label for="">Caja de Compensación Familiar</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.ers').showSpan">-</span>
                <input type="text" formControlName="ers" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.ers').showDiv" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions" *ngIf="isMyResume || allowedRoles([1]) ||isMyChild">
      <button class="button button--blue" type="submit" *ngIf="isEditing">
        Guardar
      </button>
      <button class="button button--blue" type="button" (click)="isEditing = false" *ngIf="isEditing">
        Cancelar
      </button>
      <button class="button button--blue" type="button" (click)="isEditing = true" *ngIf="!isEditing">
        Editar datos
      </button>
    </div>
  </form>
</section>