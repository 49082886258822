import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Subject,
  debounceTime,
  filter,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  SchoolI,
  UserDataI,
} from 'src/app/models/observationModels/observation';
import { ObservationsExplorerService } from 'src/app/services/observations/observations-explorer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-observations-explorer',
  templateUrl: './observations-explorer.component.html',
  styleUrls: ['./observations-explorer.component.css'],
})
export class ObservationsExplorerComponent implements OnInit, OnDestroy {
  constructor(
    private _explorationsExplorer: ObservationsExplorerService,
    private _router: Router
  ) {}

  @ViewChild('userInput') userInput: ElementRef;

  public schoolList: SchoolI[] = [];
  public filteredUsers: UserDataI[] = [];
  private _userSubject$: Subject<string> = new Subject();
  public pagePath: string = environment.pagePath;
  private _userInputValue: string = '';
  private _selectedSchoolId: number = 0;
  private _destroy$: Subject<void> = new Subject();
  private _schoolSubject$: Subject<number> = new Subject();

  ngOnInit(): void {
    this.getCurrentUserInfo();
  }

  private getCurrentUserInfo() {
    const userId = localStorage.getItem('currentUser');

    const currentUserFilters = JSON.stringify({
      where: { id: Number(userId) },
      include: [{ roleMappings: ['role', 'school'] }],
    });

    this._explorationsExplorer
      .getUserInfo(currentUserFilters)
      .pipe(
        map((users) => users[0]),
        switchMap((userInfo: UserDataI) => {
          const { roleMappings } = userInfo;

          const schoolFilter = JSON.stringify({
            where: { DepenSchoolID: roleMappings![0].SchoolID },
          });

          return this._explorationsExplorer.getSchools(schoolFilter).pipe(
            map((schools) => ({
              schools: [...schools, roleMappings![0].school],
              roleMappings,
            }))
          );
        })
      )
      .subscribe({
        next: ({ schools, roleMappings }) => {
          const roleIds = roleMappings.map((e) => e.roleId);

          //Allowed roles
          if (![29, 32, 3, 8, 1].some((e) => roleIds.includes(e))) {
            this._router.navigate(['/']);
            return;
          }

          this.schoolList = schools;
          if(this.schoolList.length > 0)
            this._selectedSchoolId === this.schoolList[0].id;

          this.listenForUserChanges();
          this.listenForSchoolChanges();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  private listenForUserChanges() {
    //Listen for guest search input
    this._userSubject$
      .pipe(
        takeUntil(this._destroy$),
        filter((value) => value.length > 3),
        debounceTime(300),
        switchMap((value) => {
          this._userInputValue = value;
          return this.userQry();
        })
      )
      .subscribe((users) => {
        this.filteredUsers = users;
      });
  }

  private listenForSchoolChanges() {
    this._schoolSubject$
      .pipe(
        takeUntil(this._destroy$),
        switchMap((schoolId) => {
          this._selectedSchoolId = schoolId;
          if (!this._userInputValue) return of([]);
          return this.userQry();
        })
      )
      .subscribe({
        next: (users) => {
          this.filteredUsers = users;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  public findUser(event: Event) {
    const target = event.target as HTMLInputElement;
    this._userSubject$.next(target.value);
  }

  public onChangeSchool(event: Event) {
    const schoolValue = (event.target as HTMLInputElement).value;
    this._schoolSubject$.next(Number(schoolValue));
  }

  private userQry() {
    const filter = JSON.stringify({
      where: {
        or: [
          { fullName: { regexp: `.*${this._userInputValue.toLowerCase().replace(/\s/g, '.*')}.*` } },
          { CedocEmail: { regexp: `/${this._userInputValue}/i` } },
          { Document: { regexp: `/${this._userInputValue}/i` } },
        ],
      },
      limit: 20,
    });

    const allSchoolsId = this.schoolList.map((e) => e.id);
    return this._explorationsExplorer.getViewUserInfo(filter).pipe(
      takeUntil(this._destroy$),
      map((results: UserDataI[]) =>
        results.filter((result: UserDataI) => {
          const schoolId = result.roleMappings[0]?.SchoolID;
          const roleIds = result.roleMappings.map((e) => e.roleId);

          if (!schoolId || !roleIds.includes(2)) return false;
          return Number(this._selectedSchoolId) !== 0
            ? schoolId === Number(this._selectedSchoolId)
            : allSchoolsId.includes(schoolId);
        })
      )
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
