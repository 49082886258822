<section class="explorer-container">
  <h2 class="explorer-title">Buscar observaciones</h2>

  <div class="explorer-filters" >
    <div class="user-search__input">
      <em class="icon-i-search"></em>
      <input
        type="text"
        placeholder="Buscar por correo, documento o nombre"
        (keyup)="findUser($event)"
      />
    </div>

    <div class="school-select">
      <select (change)="onChangeSchool($event)">
        <option [value]="0" disabled selected>Seleccionar Liceo</option>
        <option [value]="school.id" [selected]="i === 0" *ngFor="let school of schoolList; let i = index">
          {{ school.NameTSchool }}
        </option>
      </select>
      <em class="icon-i-angle-right"></em>
    </div>
  </div>

  <div class="explorer-table__container">
    <table class="explorer-table">
      <caption
        class="explorer-table__caption"
        *ngIf="filteredUsers.length === 0"
      >
        Sin datos
      </caption>
      <thead class="explorer-table__header">
        <tr>
          <th [ngStyle]="{ width: '300px' }">Estudiante</th>
          <th>Correo</th>
          <th>Documento</th>
          <th>Grado</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody class="explorer-table__data">
        <tr *ngFor="let item of filteredUsers">
          <td>
            <div class="user-info">
              <img class="user-info__picture" [src]="item.Photo" alt="" *ngIf="item.Photo" />
              <em class="icon-i-profile" *ngIf="!item.Photo"></em>
              <span class="user-info__name">
                {{ item.Name1 | titlecase }}
                {{ item.LastName1 | titlecase }}
              </span>
            </div>
          </td>
          <td>{{ item.CedocEmail }}</td>
          <td>{{ item.UserDocuments?.[0]?.Document }}</td>
          <td>{{ item.UserCourses?.[0]?.coursesOfer?.NameCourseOfer || 'Sin grado' }}</td>
          <td>
            <a
              [href]="pagePath + '/academicStatus/observations/' + item.id"
              target="_blank"
              >Ver</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
