import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApprovalFilterComponent } from './approval-filter/approval-filter.component';
import { ApprovalListHeaderComponent } from './approval-list-header/approval-list-header.component';
import { ApprovalModalComponent } from './approval-modal/approval-modal.component';
import { ApprovalRolesDirective } from 'src/app/directives/approval-roles.directive';

@NgModule( {
  declarations: [
    ApprovalFilterComponent,
    ApprovalListHeaderComponent,
    ApprovalModalComponent,
    ApprovalRolesDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ApprovalFilterComponent,
    ApprovalListHeaderComponent,
    ApprovalModalComponent,
    ApprovalRolesDirective
  ]
} )
export class CommonApprovalModule { }
