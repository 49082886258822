import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpService } from './services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { AttachmentService } from './services/attachment.service';
import { ApprovalComponent } from './pages/approval/approval.component';
import { HonourGraduateComponent } from './pages/honour-graduate/honour-graduate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { HonourRollComponent } from './pages/honour-roll/honour-roll.component';
import { RegisterChildrenComponent } from './pages/register-children/register-children.component';
import { CustomSearchSelectModule } from './components/shared/custom-search-select/custom-search-select.module';
import { ObservationsComponent } from './pages/observations/observations.component';
import { RegisterChildrenModule } from './components/register-children/register-children.module';
import { CedocResumeComponent } from './pages/resume/cedoc-resume/cedoc-resume.component';
import { CelicResumeComponent } from './pages/resume/celic-resume/celic-resume.component';
import { ObservationsModule } from './components/observations/observations.module';
import { ObservationsExplorerComponent } from './pages/observations-explorer/observations.component';
import { ApprovalListFormalModule } from './components/approval/approval-list-formal/approval-list-formal.module';
import { ApprovalListMilitaryModule } from './components/approval/approval-list-military/approval-list-military.module';
import { ApprovalListSuperiorModule } from './components/approval/approval-list-superior/approval-list-superior.module';
import { ApprovalCardComponent } from './components/approval/approval-card/approval-card.component';
import { ApprovalHeaderComponent } from './components/approval/approval-header/approval-header.component';
import { NotesModalComponent } from './components/approval/common/notes-modal/notes-modal.component';
import { CelicApprovalListFormalModule } from './components/approval/celic-approval-list-formal/celic-approval-list-formal.module';

@NgModule( {
  declarations: [
    //#region Approval
    ApprovalCardComponent,
    ApprovalHeaderComponent,
    ApprovalComponent,
    NotesModalComponent,
    //#endregion

    HonourGraduateComponent,
    HonourRollComponent,

    AppComponent,
    EmptyRouteComponent,
    RegisterChildrenComponent,
    ObservationsComponent,
    ObservationsExplorerComponent,

    CedocResumeComponent,
    CelicResumeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    //#region Approval
    ApprovalListFormalModule,
    CelicApprovalListFormalModule,
    ApprovalListMilitaryModule,
    ApprovalListSuperiorModule,
    //#endregion

    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    CustomSearchSelectModule,
    RegisterChildrenModule,
    ObservationsModule,
  ],
  providers: [
    AttachmentService,
    HttpService,
    TitleCasePipe,
    DatePipe
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule { }
